import { useState } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@watershed/ui-core/components/Button';
import LogInIcon from '@watershed/icons/components/LogIn';
import { getAuthErrorMessage } from '@watershed/shared-universal/authConstants';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import LoginHeader from '@watershed/shared-frontend/components/LoginHeader';
import { useCheckAdminAuthenticationQuery } from '@watershed/shared-frontend/generated/urql';
import { useRouter } from 'next/router';
import getQueryStringRedirect, {
  useQueryStringRedirect,
} from '@watershed/shared-frontend/utils/getQueryStringRedirect';
import { ORGS_ROUTE } from '@watershed/shared-universal/adminRoutes';
import { getWorkosHandoffUrl } from '@watershed/shared-universal/utils/workosUtils';
import gql from 'graphql-tag';
import Redirect from '@watershed/shared-frontend/components/Redirect';
import { submitEmailPassword } from '@watershed/shared-frontend/utils/loginUtils';
import { Form, Formik } from 'formik';
import TextField from '@watershed/ui-core/components/Form/TextField';
import ProgressButton from '@watershed/ui-core/components/ProgressButton';
import { useOwner } from '@watershed/shared-frontend/hooks/useOwner';
import { Teams } from '@watershed/constants/teams';

gql`
  # This minimal query is used simply to check whether or not the admin is
  # authenticated: the request will succeed it they are, get a 401 error they
  # are not.
  query CheckAdminAuthentication {
    activeWatershedEmployee {
      id
    }
  }
`;

function WorkosAuthButton() {
  const redirect = useQueryStringRedirect();
  const workosHandoffUrl = getWorkosHandoffUrl({
    domain: 'watershedclimate.com',
    provider: 'GoogleOAuth',
    redirect,
  });

  return (
    <Button
      color="primary"
      startIcon={<LogInIcon />}
      href={workosHandoffUrl}
      fullWidth
    >
      Log in with SSO
    </Button>
  );
}

function PasswordAuthSection({
  email,
  onSubmit,
  fetching,
}: {
  email: string | undefined;
  onSubmit: (password: string, email: string) => Promise<void>;
  fetching: boolean;
}) {
  return (
    <Formik
      initialValues={{ email: email ?? '', password: '' }}
      onSubmit={(values) => onSubmit(values.password, values.email)}
    >
      <Form>
        <Stack gap={2}>
          <TextField
            id="email"
            type="email"
            autoComplete="email"
            enterKeyHint="next"
            label="Email"
            autoFocus
          />
          <TextField id="password" type="password" label="Password" autoFocus />
          <ProgressButton
            type="submit"
            fullWidth
            label="Log in"
            isInProgress={fetching}
            disabled={fetching}
          />
        </Stack>
      </Form>
    </Formik>
  );
}

/**
 * Check to see if admin user is already logged in. If so, redirect to the
 * specified `?redirect=` query param if present or the home page.
 */
function useGetLoggedInRedirectPath() {
  const router = useRouter();
  const [result] = useCheckAdminAuthenticationQuery();
  if (result.data?.activeWatershedEmployee) {
    return getQueryStringRedirect(router.query) ?? ORGS_ROUTE;
  }
  return null;
}

interface EmailLoginState {
  state: 'email';
  fetching: boolean;
  email?: string;
  error?: string;
}

interface ErrorState {
  state: 'error';
  fetching: false;
  email: string;
  error: string;
}

type LoginState = EmailLoginState | ErrorState;

const EMAIL_LOCALSTORAGE_KEY = 'adminLoginEmail';

export default function AdminLoginPage() {
  useOwner(Teams.EnterpriseFoundations);
  const router = useRouter();
  const email =
    window.localStorage.getItem(EMAIL_LOCALSTORAGE_KEY) ?? undefined;
  const [authError] = useQueryParam('authError');
  const [state, setState] = useState<LoginState>({
    state: 'email',
    email,
    fetching: false,
    error: authError ? getAuthErrorMessage(authError) : undefined,
  });

  const isDevelopment = process.env.NODE_ENV === 'development';

  const loggedInRedirectPath = useGetLoggedInRedirectPath();
  if (loggedInRedirectPath) {
    return <Redirect to={loggedInRedirectPath} />;
  }

  const handleSubmitPassword = async (password: string, email: string) => {
    window.localStorage.setItem(EMAIL_LOCALSTORAGE_KEY, email);
    setState((state) => ({ ...state, state: 'email', fetching: true }));
    try {
      await submitEmailPassword(email, password);
      setState((state) => ({ ...state, fetching: false }));
      const redirectPath = getQueryStringRedirect(router.query) || '/';
      // We do a hard page-load (instead of a client-side redirect) to avoid
      // potential bugs related to authentication state by refreshing that state
      // completely.
      window.location.replace(redirectPath);
    } catch (ex) {
      const error = ex as Error;
      setState((state) => ({
        ...state,
        error: error.message,
        fetching: false,
      }));
    }
  };

  return (
    <Card
      sx={{
        width: '400px',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: (theme) => theme.spacing(0, 3, 2),
        overflow: 'visible', // For <LoginHeader />
      }}
    >
      <LoginHeader title="Log in to Watershed Admin" error={state.error} />
      <Stack gap={2}>
        {isDevelopment && (
          <>
            <ErrorBox level="warning" textAlign="center">
              Development only:
              <br />
              You must login via password. Try changing
              organization.password_auth_disabled to false.
            </ErrorBox>
            <PasswordAuthSection
              email={state.email}
              fetching={state.fetching}
              onSubmit={handleSubmitPassword}
            />
          </>
        )}
        {!isDevelopment && <WorkosAuthButton />}
      </Stack>
    </Card>
  );
}

// We use this to signal to the layout that we don't want to include the LoggedIn wrapper
AdminLoginPage.doesAllowUnauthenticatedUsers = true;
